import { createBrowserRouter } from "react-router-dom";

import App from "../App";
import Home from "../Home";
import Services from "../Services";
import About from "../About";
import ContactUs from "../ContactUs";
import { ROUTES } from "../../constants/routes";

const router = createBrowserRouter([
  {
    path: ROUTES.home,
    element: <App />,
    children: [
      {
        path: ROUTES.home,
        element: <Home />,
      },
      {
        path: ROUTES.services,
        element: <Services />,
      },
      {
        path: ROUTES.about,
        element: <About />,
      },
      {
        path: ROUTES.contactUs,
        element: <ContactUs />,
      },
    ],
  },
]);

export default router;
