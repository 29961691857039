import classnames from "classnames";

import styles from "./Button.module.css";

type ButtonTypes = "Primary" | "Secondary";

type Props = {
  type?: ButtonTypes;
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
};

const ButtonTypeToStyleMap = {
  Primary: styles.primary,
  Secondary: styles.secondary,
};

const Button = ({
  type = "Secondary",
  children,
  onClick,
  className,
}: Props) => {
  return (
    <div
      className={classnames(
        styles.button,
        ButtonTypeToStyleMap[type],
        className
      )}
      onClick={onClick}
    >
      <div className={styles.children}> {children}</div>
    </div>
  );
};

export default Button;
