import classnames from "classnames";
import { NavLink } from "react-router-dom";

import styles from "./NavigationLink.module.css";

type Props = {
  children: React.ReactNode;
  to: string;
};

const NavigationLink = ({ children, to }: Props) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classnames(styles.wrapper, isActive && styles.active)
      }
    >
      {children}
    </NavLink>
  );
};

export default NavigationLink;
