import { ICONS, IconsType } from "../../constants/icons";

export type { IconsType } from "../../constants/icons";

type Props = React.SVGProps<SVGSVGElement> & {
  name: IconsType;
};

const Icons = (props: Props) => {
  const { name, ...svgProps } = props;
  return (
    <svg
      viewBox={`${ICONS[name].x || 0} ${ICONS[name].y || 0} ${
        ICONS[name].size
      } ${ICONS[name].size}`}
      fill="currentColor"
      height="1em"
      width="1em"
      {...svgProps}
    >
      <path d={ICONS[name].path} />
    </svg>
  );
};

export default Icons;
