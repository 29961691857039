import React, { useState, useRef, useEffect } from "react";
import classnames from "classnames";

import styles from "./Dropdown.module.css";

interface Option {
  value: string;
  label: string;
}

interface CustomDropdownProps {
  children: React.ReactNode;
  options: Option[];
  onSelect?: (option: Option) => void;
  selected: Option | undefined;
}

const Dropdown: React.FC<CustomDropdownProps> = ({
  children,
  options,
  selected,
  onSelect,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option | null>(
    selected || null
  );
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const close = () => setIsOpen(false);

  const handleSelect = (option: Option) => {
    setSelectedOption(option);
    close();
    onSelect && onSelect(option);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isDisabled = (option: Option) => selectedOption?.value === option.value;

  return (
    <div ref={dropdownRef} className={styles.dropdown}>
      <div onClick={handleToggle}>{children}</div>
      {isOpen && (
        <div className={styles.dropdownMenu}>
          {options.map((option) => (
            <div
              key={option.value}
              className={classnames(
                styles.item,
                isDisabled(option) && styles.disabledItem
              )}
              onClick={() =>
                isDisabled(option) ? close() : handleSelect(option)
              }
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
